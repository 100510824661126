<template>
  <b-overlay
      :show="users === null"
      rounded="sm"
  >

    <div v-if="users !== null">

      <user-list-add-new
          :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
          @refetch-data="refreshData"
      />

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >
        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <b-col cols="12" md="12">
              <h1>Manage Users</h1>
            </b-col>
            <!-- Search -->
            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  class="mb-2 mt-25"
                  label="Count"
              >
                <v-select
                    v-model="perPage"
                    :clearable="false"
                    :options="perPageOptions"
                    class="per-page-selector w-100 d-inline-block "
                />

              </b-form-group>

            </b-col>
            <b-col
                class="mt-25"
                cols="12"
                md="3"
            >
              <b-form-group label="Search">
                <Debouncer @setValue="setQuery"></Debouncer>
              </b-form-group>

            </b-col>

            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  class="mb-2 mt-25"
                  label="User Status"
              >
                <v-select
                    v-model="selectedStatus"
                    :options="userStatuses"
                    :reduce="name => name.value"
                    class="per-page-selector w-100 d-inline-block "
                    label="name"
                    placeholder="select"
                />

              </b-form-group>

            </b-col>
            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  class="mb-2 mt-25"
                  label="User Authority"
              >
                <v-select
                    v-model="selectedType"
                    :options="userTypes"
                    :reduce="name => name.value"
                    class="per-page-selector w-100 d-inline-block "
                    label="name"
                    placeholder="select"
                />

              </b-form-group>

            </b-col>
            <b-col
                cols="12"
                md="3"
            >
              <b-button
                  class="btn-tour-skip mr-1"
                  variant="primary"
                  @click="$router.push(`/apps/users/create-user`)"
              >
                <span class=" align-middle">Create</span>

              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="users"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            sort-by.sync
            striped
        >

          <template #cell(fullName)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    :src="data.item.avatarImage"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
                    :variant="`success`"
                    size="45"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.fullName }}
              </b-link>
              <small class="text-muted">@{{ data.item.id }}</small>
            </b-media>
          </template>

          <template #cell(userStatus)="data">
            <b-badge
                v-if="data.item.userStatus==='Active'"
                class="mx-25"
                pill
                variant="primary"
            >
                      <span>
                        {{ data.item.userStatus }}
                      </span>

            </b-badge>
            <b-badge
                v-if="data.item.userStatus==='InActive'"

                class="mx-25"
                pill
                variant="danger"
            >
                      <span>
                        {{ data.item.userStatus }}
                      </span>

            </b-badge>
            <b-badge
                v-if="data.item.userStatus==='Limited'"

                class="mx-25"
                pill
                variant="warning"
            >
                      <span>
                        {{ data.item.userStatus }}
                      </span>

            </b-badge>
            <b-badge
                v-if="data.item.userStatus==='PreRegister'"

                class="mx-25"
                pill
                variant="secondary"
            >
                      <span>
                        {{ data.item.userStatus }}
                      </span>

            </b-badge>
          </template>
          <template #cell(userType)="data">
            <b-badge
                class="mx-25"
                pill
                variant="primary"
            >
                      <span>
                        {{ data.item.userType }}
                      </span>

            </b-badge>
          </template>
          <!-- Column: edit -->
          <template #cell(edit)="data">
            <b-link :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCounts"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserListAddNew from './UserListAddNew.vue'
import { UsersGetAllRequest } from '@/libs/Api/Users'
import Helper from '@/libs/Helper'
import Debouncer from '@/views/components/debouncer.vue'

export default {
  title: 'users list',
  data() {
    return {
      users: null,
      currentPage: 1,
      totalCounts: null,
      perPage: 10,
      perState: '',
      baseURL: Helper.baseUrl,
      userStatuses: [
        {
          name: 'InActive',
          value: 'InActive'
        },
        {
          name: 'Active',
          value: 'Active'
        },
        {
          name: 'PreRegister (KYC)',
          value: 'PreRegister'
        },
        {}

      ],
      userTypes: [
        {
          name: 'Customer',
          value: 'DefaultUser'
        },
        {
          name: 'Admin',
          value: 'AdminUser'
        },
        {
          name: 'Super Admin',
          value: 'SuperAdmin'
        },
        {
          name: 'Booster',
          value: 'BoosterUser'
        },
        {
          name: 'Currency Seller',
          value: 'CurrencySellerUser'
        },
        {
          name: 'Currency Farmer',
          value: 'CurrencyFarmerUser'
        },
        {
          name: 'Influencer',
          value: 'InfluencerUser'
        },
        {
          name: 'Author',
          value: 'AuthorUser'
        },

      ],
      selectedType: '',
      selectedStatus: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'fullName',
          label: 'User',
          sortable: true
        },
        {
          key: 'userType',
          label: 'authority',
          sortable: true
        },
        {
          key: 'userStatus',
          label: 'status',
          sortable: true
        },
        // { key: 'mobile',label: 'موبایل'},
        {
          key: 'edit',
          label: 'Edit'
        },
      ],
      searchQuery: '',
      isAddNewUserSidebarActive: false,
    }
  },
  async created() {
    await this.getUsers()
  },
  methods: {
    setQuery(data) {
      this.searchQuery = data
      this.getUsers()
    },
    refreshData() {
      this.getUsers()
    },
    async getUsers() {
      let _this = this
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery,
        status: _this.selectedStatus ? _this.selectedStatus : '',
        type: _this.selectedType ? _this.selectedType : '',
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this)
      usersGetAllRequest.setParams(data)
      await usersGetAllRequest.fetch(function (content) {
        _this.users = content.data.data
        _this.totalCounts = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    }
  },
  watch: {
    perPage: function (nv, ov) {
      this.getUsers()
    },
    currentPage: function (nv, ov) {
      this.getUsers()
    },
    selectedStatus: function (val) {
      this.getUsers()
    },
    selectedType: function (val) {
      this.getUsers()
    }

  },
  components: {
    Debouncer,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
